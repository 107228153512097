import { ResourceBatch } from 'ohzi-core';
import FontSizeController from './js/components/FontSizeController';

let is_mobile = !!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
let is_ios = !!navigator.userAgent.match(/(iPhone|iPod|iPad)/);
let is_ipad = navigator.userAgent.match(/(iPad)/);

let loader = document.querySelector('.loader');
let progress_bar = document.querySelector('.loader__progress-bar-fill');
let second_step = false;

let api_parameters = {
  is_mobile: is_mobile,
  is_ios: is_ios,
  is_ipad: is_ipad
};

let on_api_ready = () =>
{
  loader.classList.add('hidden');

  ViewApi.init(api_parameters);
  ViewApi.resource_loading_completed();
  ViewApi.start();

  window.addEventListener('resize', function()
  {
    ViewApi.resize_canvas();
  });
};

window.app = ViewApi.app;

let on_config_ready = () =>
{
  second_step = true;

  let config = ViewApi.resource_container.get_resource('config');

  batch = new ResourceBatch();

  // batch.add_texture("fisheye_tex", "textures/snapshot_250.jpg");
  // batch.add_texture("point", "textures/point.png");

  batch.add_json('why', '/data/why.json');
  // batch.add_json('why_sections', '/data/why_sections.json');

  batch.add_json('what', '/data/what.json');
  // batch.add_json('what_sections', '/data/what_sections.json');

  batch.add_json('how', '/data/how.json');
  batch.add_json('how_sections', '/data/how_sections.json');

  batch.add_json('how_results', '/data/how_results.json');
  // batch.add_json('how_results_sections', '/data/how_results_sections.json');

  batch.add_json('value', '/data/value.json');
  // batch.add_json('value_sections', '/data/value_sections.json');

  batch.add_json('casestudies', '/data/casestudies.json');
  // batch.add_json('casestudies_sections', '/data/casestudies_sections.json');

  batch.add_json('about', '/data/about.json');
  // batch.add_json('about_sections', '/data/about_sections.json');

  batch.add_json('future', '/data/future.json');
  // batch.add_json('future_sections', '/data/future_sections.json');

  batch.add_gltf('path_points', '/path_points.glb');

  batch.add_gltf('speed_shapes', '/models/speed_shapes.glb');

  batch.load(ViewApi.resource_container);

  check_resource_loading(batch, on_api_ready);
};

let check_resource_loading = (batch, on_resources_loaded) =>
{
  if (second_step)
  {
    progress_bar.style.width = `${batch.get_progress() * 100}%`;
  }

  if (batch.loading_finished)
  {
    if (batch.has_errors)
    {
      batch.print_errors();
    }
    else
    {
      on_resources_loaded();
    }
  }
  else
  {
    setTimeout(function()
    {
      check_resource_loading(batch, on_resources_loaded);
    }, 200);
  }
};

let batch = new ResourceBatch();
batch.add_json('config', '/data/config.json');
batch.load(ViewApi.resource_container);

check_resource_loading(batch, on_config_ready);

FontSizeController.on_resize(document.querySelector('html'), is_mobile);

/* These are the modifications: */
history.pushState = (f => function pushState()
{
  var ret = f.apply(this, arguments);
  window.dispatchEvent(new Event('pushstate'));
  window.dispatchEvent(new Event('locationchange'));
  return ret;
})(history.pushState);

history.replaceState = (f => function replaceState()
{
  var ret = f.apply(this, arguments);
  window.dispatchEvent(new Event('replacestate'));
  window.dispatchEvent(new Event('locationchange'));
  return ret;
})(history.replaceState);

window.addEventListener('popstate', () =>
{
  window.dispatchEvent(new Event('locationchange'));
});

const video = document.querySelector('.home__image-video-video');

let webm = false;
if (video.canPlayType)
{
  webm = video.canPlayType('video/webm;codecs="vp9,opus"') !== '';
}

const extension = webm ? 'webm' : 'mp4';

video.src = `${video.dataset.src}.${extension}`;
