class FontSizeController
{
  constructor()
  {
    this.html_font_size = 13;
  }

  on_resize(html_el, is_mobile)
  {
    // if (!is_mobile)
    // {
    //   let font_size = 1;

    //   // if(Screen.height > Screen.width)
    //   //   font_size = 1 / (200 / Screen.width);
    //   // else
    //   // console.log(this.html_font_size);
    //   font_size = 1 / (1000 / html_el.clientHeight);

    //   // font_size = THREE.Math.clamp(font_size, 0, 1);
    //   this.html_font_size = font_size * 16;
    //   this.html_font_size = Math.round(this.html_font_size);
    // }

    // html_el.style.fontSize = `${this.html_font_size}px`;
    // console.log(this.html_font_size);
  }
}

export default new FontSizeController();
